import React from 'react'

export default function Row({ goToRowCol, vals, solution, rowVal, currentRow, currentCol }) {

    const squareClicked = (row, col) => {
        if (row === 0 && col === 0) {
            return
        }
        if (row === 1 && col === 2) {
            return
        }
        if (row === 2 && col === 1) {
            return
        }
        if (row === 1 && col === 1) {
            return
        }
        goToRowCol(row, col)
    }

    return (
        <div className='row'>
            {
                vals.map((v, i) => {
                    if (v) {
                        if (rowVal === 1 && i === 1) {
                            if (v.key) {
                                return <div key={i} className="green-not-animated">{v.key}</div>
                            } else {
                                return <div key={i} className="green-not-animated">{solution[1][1]}</div>
                            }
                        } else {
                            if (rowVal === 0 && i === 0 && !v.key) {
                                return <div key={i} className='white' onClick={() => squareClicked(rowVal, i)}>{solution[0][0]}</div>
                            }
                            if (rowVal === 1 && i === 2 && !v.key) {
                                return <div key={i} className='white' onClick={() => squareClicked(rowVal, i)}>{solution[1][2]}</div>
                            }
                            if (rowVal === 2 && i === 1 && !v.key) {
                                return <div key={i} className='white' onClick={() => squareClicked(rowVal, i)}>{solution[2][1]}</div>
                            }
                            if (rowVal === currentRow && i === currentCol) {
                                let className = 'current ' + v.color
                                return <div key={i} className={className} onClick={() => squareClicked(rowVal, i)}>{v.key}</div>
                            } else {
                                return <div key={i} className={v.color} onClick={() => squareClicked(rowVal, i)}>{v.key}</div>
                            }
                        }
                    } else {
                        if (rowVal === currentRow && i === currentCol) {
                            return <div key={i} className='current white' onClick={() => squareClicked(rowVal, i)}></div>
                        } else {
                            return <div key={i} className='white' onClick={() => squareClicked(rowVal, i)}></div>
                        }
                    }
                })
            }
        </div>
    )
}
