import React, { useEffect, useState } from 'react';
import useMathGame from '../hooks/useMathGame';
import Grid from './Grid';
import Keypad from './Keypad';
import Modal from './Modal';
import Directions from  './Directions'
import { FaQuestionCircle } from 'react-icons/fa';

export default function MathGame({ solution }) {
    const {currFormattedGuess, turn, isCorrect, handleKeyup, goToRowCol, usedKeys, row, col, history} = useMathGame(solution)
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        let shouldShowDirections = JSON.parse(window.localStorage.getItem('showDirections'))
        if (shouldShowDirections === null || shouldShowDirections === true) {
            document.getElementById('directions-modal').style.display = "block";
        } else {
            document.getElementById('directions-modal').style.display = "none";
        }
        if (isCorrect) {
            // User wins
            setTimeout(() => setShowModal(true), 1400)
        }
        if (turn > 2) {
            // User lost
            setTimeout(() => setShowModal(true), 1400)
        }
    }, [currFormattedGuess, isCorrect, turn])

    const showDirectionsClickedBtn = () => {
        document.getElementById('directions-modal').style.display = "block";
    }

    return (
        <div id='math-game-main'>
            <div className='header'>
                <h1>Square in One</h1>
                <button onClick={showDirectionsClickedBtn} className='directions-btn'>
                    <FaQuestionCircle size="2x"/>
                </button>
            </div>
            <h4>{turn} out of 3</h4>
            <Grid goToRowCol={goToRowCol} currFormattedGuess={currFormattedGuess} solution={solution} currentRow={row} currentCol={col} />
            <Keypad handleKeyup={handleKeyup} usedKeys={usedKeys} currentRow={row} currentCol={col} />
            {showModal && <Modal isCorrect={isCorrect} turn={turn} solution={solution} history={history} />}
            <Directions id='directions-modal' />
        </div>
    )
}
