import React from 'react'
import Row from './Row'

export default function Grid({ goToRowCol, currFormattedGuess, solution, currentRow, currentCol }) {
    return (
        <div>
            <Row key={0} goToRowCol={goToRowCol} rowVal={0} vals={currFormattedGuess[0]} solution={solution} currentRow={currentRow} currentCol={currentCol}/>
            <Row key={1} goToRowCol={goToRowCol} rowVal={1} vals={currFormattedGuess[1]} solution={solution} currentRow={currentRow} currentCol={currentCol}/>
            <Row key={2} goToRowCol={goToRowCol} rowVal={2} vals={currFormattedGuess[2]} solution={solution} currentRow={currentRow} currentCol={currentCol}/>
        </div>
    )
}
