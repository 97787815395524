import React, { useEffect, useState } from "react"

// usedKeys
export default function Keypad({ handleKeyup, usedKeys, currentRow, currentCol }) {
    const[keys, setKeys] = useState(null)

    let jsonData = require('../db.json')
    let parsedData = JSON.parse(JSON.stringify(jsonData))
    let keysJson = parsedData["keys"]
    
    useEffect(() => {
        setKeys(keysJson)
    }, [])

    const handleClick = (key) => {
        if (key === "Delete") {
            handleKeyup({key: "Backspace"})
            return
        }
        handleKeyup({key: key})
    }

    return (
        <div className="keypad">
            {keys && keys.map((l) => {
                const color = usedKeys[l.key]
                if (l.key === 'Enter') {
                    if (currentRow === 4 && currentCol === 4) {
                        const style = {
                            border: '2px solid black'
                        };
                        return (
                            <div key={l.key} className='special' style={style} onClick={() => handleClick(l.key)}>{l.key}</div>
                        )
                    }
                    return (
                        <div key={l.key} className='special' onClick={() => handleClick(l.key)}>{l.key}</div>
                    )
                }
                if (l.key === 'Back' || l.key === 'Delete' || l.key === 'Next') {
                    return (
                        <div key={l.key} className='special' onClick={() => handleClick(l.key)}>{l.key}</div>
                    )
                }
                return (
                    <div key={l.key} className={color} onClick={() => handleClick(l.key)}>{l.key}</div>
                )
            })}
        </div>
    )
}