import MathGame from "./components/MathGame";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  let jsonData = require('./db.json')
  let parsedData = JSON.parse(JSON.stringify(jsonData))
  let mathGames = parsedData["games"]
  let boardNumber = getGameBoard()
  let solution = mathGames[boardNumber].board

  return (
    <div className="App">
      {solution && <MathGame solution={solution} />}
      <ToastContainer />
    </div>
  );
}

function getGameBoard() {
  var now = new Date()
  now.setHours(0, 0, 0, 0)
  let releaseDate = new Date('2022-12-21')
  releaseDate.setHours(0, 0, 0, 0)
  let difference = Math.abs(releaseDate.getTime() - now.getTime())
  let days = Math.ceil(difference / (1000 * 3600 * 24))
  return days - 1
}

export default App
