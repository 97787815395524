import React from "react"
import { BsFillShareFill } from "react-icons/bs"

export default function Modal({ isCorrect, turn, solution, history }) {
    const shareButtonClicked = () => {
        let emojis = ''
        for (let i = 0; i < history.length; i += 1) {
            if (history[i] === 0) {
                emojis += '⚪'
            } else if (history[i] === 1) {
                emojis += '🟡'
            } else if (history[i] === 2) {
                emojis += '🟢'
            }
        }
        let text = 'Square in One ' + turn + '/3\n' + emojis
        if (navigator.share) {
            navigator
              .share({
                title: "",
                text: text,
                url: '',
              })
              .then(() => {
                console.log('Successfully shared');
              })
              .catch(error => {
                console.error('Something went wrong sharing the blog', error);
              });
          }
    }

    return (
        <div className="modal">
            {isCorrect &&  (
                <div className="outer">
                    <h1>Congrats, you won!</h1>
                    <p>You found the solution in {turn}/3 guesses</p>
                    <div className="row-solution">
                        <div>{solution[0][0]}</div>
                        <div>{solution[0][1]}</div>
                        <div>{solution[0][2]}</div>
                    </div>
                    <div className="row-solution">
                        <div>{solution[1][0]}</div>
                        <div>{solution[1][1]}</div>
                        <div>{solution[1][2]}</div>
                    </div>
                    <div className="row-solution">
                        <div>{solution[2][0]}</div>
                        <div>{solution[2][1]}</div>
                        <div>{solution[2][2]}</div>
                    </div>
                    <div className='share-box'>
                        <div onClick={shareButtonClicked}>Share <BsFillShareFill style={{color: 'white'}}/> </div>
                    </div>
                </div>
            )}
            {!isCorrect &&  (
                <div className="outer">
                    <h1>Unfortunately, you lost</h1>
                    <p>Better luck next time!</p>
                    <div className="row-solution">
                        <div>{solution[0][0]}</div>
                        <div>{solution[0][1]}</div>
                        <div>{solution[0][2]}</div>
                    </div>
                    <div className="row-solution">
                        <div>{solution[1][0]}</div>
                        <div>{solution[1][1]}</div>
                        <div>{solution[1][2]}</div>
                    </div>
                    <div className="row-solution">
                        <div>{solution[2][0]}</div>
                        <div>{solution[2][1]}</div>
                        <div>{solution[2][2]}</div>
                    </div>
                    <div className='share-box'>
                        <div onClick={shareButtonClicked}>Share</div>
                    </div>
                </div>
            )}
        </div>
    )
}
