import { useEffect, useState } from "react"
import {toast} from 'react-toastify'
import ReactGA from "react-ga4";

const useMathGame = (solution) => {
    const [currentGuess, setCurrentGuess] = useState([[null, null, null], [null, null, null], [null, null, null]]) // Current board
    const [currFormattedGuess, setCurrFormattedGuess] = useState([[null, null, null], [null, null, null], [null, null, null]]) // Current guess formatted for display
    const [history, setHistory] = useState([]) // Array of ints representing historical guesses: 0 - nothing was right, 1 - some squares were right, 2 - everything was right
    const [turn, setTurn] = useState(0) // The current turn
    const [isCorrect, setIsCorrect] = useState(false) // Whether or not the guess was correct
    // Current location on the board
    const [row, setRow] = useState(0)
    const [col, setCol] = useState(1)
    const [usedKeys, setUsedKeys] = useState({}) // The numbers that have been used

    var isOngoingGame = false

    /*
    Starting board:
    [*, _, _]
    [_, *, *]
    [_, *, _]
    */

    useEffect(() => {
        ReactGA.initialize("G-XK47Y9CP5V");
        let storageCurrGuess = JSON.parse(window.localStorage.getItem('currentGuess'))
        let storageDate = JSON.parse(window.localStorage.getItem('date'))
        var now = new Date()
        now.setHours(0, 0, 0, 0)
        now = JSON.parse(JSON.stringify(now))
        if (storageCurrGuess === null || storageCurrGuess[0][0] === null || storageCurrGuess[1][1] === null || storageCurrGuess[1][1] !== solution[1][1] || storageDate === null || storageDate !== now) {
            // New game
            isOngoingGame = false
            setCurrentGuess((prev) => {
                let newCurrent = [...prev]
                newCurrent[0][0] = solution[0][0]
                newCurrent[1][1] = solution[1][1]
                newCurrent[1][2] = solution[1][2]
                newCurrent[2][1] = solution[2][1]
                return newCurrent
            })
            window.localStorage.setItem('date', JSON.stringify(now))
            window.localStorage.setItem('currentGuess', JSON.stringify(currentGuess))
            window.localStorage.setItem('currFormattedGuess', JSON.stringify(formatInitialGuess(currentGuess)))
            setHistory([])
            setTurn(0)
            setIsCorrect(false)
            setUsedKeys({})
        } else {
            // Current Game
            isOngoingGame = true
            setCurrentGuess(storageCurrGuess)
            let formattedStorageGuess = JSON.parse(window.localStorage.getItem('currFormattedGuess'))
            if (formattedStorageGuess === null) {
                setCurrFormattedGuess([[null, null, null], [null, null, null], [null, null, null]])
            } else {
                setCurrFormattedGuess(formattedStorageGuess)
            }
            
            let historyStorage = JSON.parse(window.localStorage.getItem('history'))
            if (historyStorage === null) {
                setHistory([])
            } else {
                setHistory(historyStorage)
            }
            let turnStorage = parseInt(window.localStorage.getItem('turn'))
            if (turnStorage === null) {
                setTurn(0)
            } else {
                setTurn(turnStorage)
            }
            let correctStorage = JSON.parse(window.localStorage.getItem('isCorrect'))
            if (correctStorage === null) {
                setIsCorrect(false)
            } else {
                setIsCorrect(correctStorage)
            }
            let usedKeysStorage = JSON.parse(window.localStorage.getItem('usedKeys'))
            if (usedKeysStorage === null) {
                setUsedKeys({})
            } else {
                setUsedKeys(usedKeysStorage)
            }
        }
        setRow(0)
        setCol(1)
    }, [])

    useEffect(() => {
        if (!isOngoingGame) {
            formatGuess()
        }
     }, [currentGuess])

    useEffect(() => {
        window.localStorage.setItem('history', JSON.stringify(history))
    }, [history])

    useEffect(() => {
        window.localStorage.setItem('turn', turn)
    }, [turn])

    useEffect(() => {
        window.localStorage.setItem('isCorrect', JSON.stringify(isCorrect))
    }, [isCorrect])

    useEffect(() => {
        window.localStorage.setItem('usedKeys', JSON.stringify(usedKeys))
    }, [usedKeys])

    const formatGuess = () => {
        let formattedGuess = [...currentGuess].map((l, i) => {
            let val = l.map((v, j) => {
                if (i === 1 && j === 1) {
                    return {key: v, color: 'green-not-animated'}
                }
                if (currFormattedGuess[i][j] && currFormattedGuess[i][j].color && currFormattedGuess[i][j].key === v) {
                    return {key: v, color: currFormattedGuess[i][j].color}
                }
                return {key: v, color: 'white'}
            })
            return val
        })
        setCurrFormattedGuess(() => {
            return formattedGuess
        })
    }

    const formatInitialGuess = (initialGuess) => {
        let formattedGuess = [...initialGuess].map((l, i) => {
            let val = l.map((v, j) => {
                if (i === 1 && j === 1) {
                    return {key: v, color: 'green-not-animated'}
                }
                if (currFormattedGuess[i][j] && currFormattedGuess[i][j].color && currFormattedGuess[i][j].key === v) {
                    return {key: v, color: currFormattedGuess[i][j].color}
                }
                return {key: v, color: 'white'}
            })
            return val
        })
        return formattedGuess
    }

    const submitGuess = () => {
        // let solutionArray = [...solution]
        let nextHistoryVal = 0
        let solutionArray = JSON.parse(JSON.stringify(solution))
        let formattedGuess = [...currentGuess].map((l) => {
            let val = l.map((v) => {
                return {key: v, color: 'grey'}
            })
            return val
        })
        // Correct location
        formattedGuess.forEach((l, i) => {
            l.forEach((v, j) => {
                if (solutionArray[i][j] === v.key) {
                    formattedGuess[i][j].color = 'green'
                    solutionArray[i][j] = null
                }
            })
        })
        // Incorrect location
        formattedGuess.forEach((l, i) => {
            l.forEach((v, j) => {
                if (solutionArray[0].includes(v.key) && v.color !== 'green') {
                    formattedGuess[i][j].color = 'yellow'
                    solutionArray[0][solutionArray.indexOf(v.key)] = null
                    nextHistoryVal = 1
                } else if (solutionArray[1].includes(v.key) && v.color !== 'green') {
                    formattedGuess[i][j].color = 'yellow'
                    solutionArray[1][solutionArray.indexOf(v.key)] = null
                    nextHistoryVal = 1
                } else if (solutionArray[2].includes(v.key) && v.color !== 'green') {
                    formattedGuess[i][j].color = 'yellow'
                    solutionArray[2][solutionArray.indexOf(v.key)] = null
                    nextHistoryVal = 1
                }
            })
        })
        formattedGuess[0][0] = {key: solution[0][0], color: 'green'}
        formattedGuess[1][1] = {key: solution[1][1], color: 'green-not-animated'}
        formattedGuess[1][2] = {key: solution[1][2], color: 'green'}
        formattedGuess[2][1] = {key: solution[2][1], color: 'green'}
        setCurrFormattedGuess(() => {
            return formattedGuess
        })
        updateKeys(formattedGuess)
        window.localStorage.setItem('currentGuess', JSON.stringify(currentGuess))
        window.localStorage.setItem('currFormattedGuess', JSON.stringify(formattedGuess))
        return nextHistoryVal
    }

    const updateKeys = (formattedGuess) => {
        setUsedKeys((prevUsedKeys) => {
            let newKeys = {...prevUsedKeys}
            formattedGuess.forEach((l, i) => {
                l.forEach((v, j) => {
                    const currentColor = newKeys[v.key]
                    if (v.color === 'green') {
                        newKeys[v.key] = 'green'
                        return
                    }
                    if (v.color === 'yellow' && currentColor !== 'green') {
                        newKeys[v.key] = 'yellow'
                        return
                    }
                    if (v.color === 'grey' && currentColor !== 'green' && currentColor !== 'yellow') {
                        newKeys[v.key] = 'grey'
                        return
                    }
                })
            })
            return newKeys
        })
    }

    const goToRowCol = (newRow, newCol) => {
        setRow(newRow)
        setCol(newCol)
    }
    
    const handleKeyup = ({ key }) => {
        if (key === "Enter") {
            if (checkMath()) {
                var nextHistoryVal = submitGuess()
                setTurn((prevTurn) => {
                    return prevTurn + 1
                })
                if (!checkIsCorrect()) {
                    if (turn === 0) {
                        ReactGA.event({
                            category: 'puzzle',
                            action: "attempted",
                            label: "attempted",
                        });
                    }
                    setRow(0)
                    setCol(1)
                } else {
                    ReactGA.event({
                        category: 'puzzle',
                        action: "solved",
                        label: "solved",
                    });
                    nextHistoryVal = 2
                }
                setHistory((prevHistory) => {
                    let newHistory = [...prevHistory]
                    newHistory.push(nextHistoryVal)
                    return newHistory
                })
            } else {
                toast("Invalid guess. Check your Math!", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    closeButton: false,
                })
            }
            return
        }
        if (key === "Backspace") { // Delete
            if (row >= 0 && row < 3 && col >= 0 && col < 3) {
                setCurrentGuess((prev) => {
                    let newCurrent = [...prev]
                    newCurrent[row][col] = null
                    newCurrent[0][0] = solution[0][0]
                    newCurrent[1][1] = solution[1][1]
                    newCurrent[1][2] = solution[1][2]
                    newCurrent[2][1] = solution[2][1]
                    return newCurrent
                })
            }
            return
        }
        if (key === "Next") {
            goToNextRowCol()
            return
        }
        if (key === "Back") {
            goToPrevRowCol()
            return
        }
        if (/^(?:[1-9]|[1-4][0-9]|50)$/.test(key)) {
            setCurrentGuess((prev) => {
                let newCurrent = [...prev]
                newCurrent[row][col] = key
                newCurrent[0][0] = solution[0][0]
                newCurrent[1][1] = solution[1][1]
                newCurrent[1][2] = solution[1][2]
                newCurrent[2][1] = solution[2][1]
                return newCurrent
            })
            goToNextRowCol()
        }
    }

    const checkIsCorrect = () => {
        for (let r = 0; r < 3; r +=1 ) {
            for (let c = 0; c < 3; c += 1) {
                if (solution[r][c] !== currentGuess[r][c]) {
                    return false
                }
            }
        }
        setIsCorrect(true)
        return true
    }

    const checkMath = () => {
        // Top row
        var sum = 0
        for (let c = 0; c < 3; c += 1) {
            if (!currentGuess[0][c]) {
                return false
            }
            sum += currentGuess[0][c]
        }
        if (sum !== solution[1][1]) {
            return false
        }
        // Bottom row
        sum = 0
        for (let c = 0; c < 3; c += 1) {
            if (!currentGuess[2][c]) {
                return false
            }
            sum += currentGuess[2][c]
        }
        if (sum !== solution[1][1]) {
            return false
        }
        // Left col
        sum = 0
        for (let r = 0; r < 3; r += 1) {
            if (!currentGuess[r][0]) {
                return false
            }
            sum += currentGuess[r][0]
        }
        if (sum !== solution[1][1]) {
            return false
        }
        // Right col
        sum = 0
        for (let r = 0; r < 3; r += 1) {
            if (!currentGuess[r][2]) {
                return false
            }
            sum += currentGuess[r][2]
        }
        if (sum !== solution[1][1]) {
            return false
        }

        return true
    }

    const goToNextRowCol = () => {
        if (row === 0) {
            if (col === 1) {
                setRow(0)
                setCol(2)
                return
            }
            if (col === 2) {
                setRow(1)
                setCol(0)
                return
            }
        }
        if (row === 1) {
            if (col === 0) {
                setRow(2)
                setCol(0)
                return
            }
        }
        if (row === 2) {
            if (col === 0) {
                setRow(2)
                setCol(2)
                return
            }
            if (col === 2) {
                setRow(4)
                setCol(4)
                return
            }
        }
    }

    const goToPrevRowCol = () => {
        if (row === 0) {
            if (col === 2) {
                setRow(0)
                setCol(1)
                return
            }
        }
        if (row === 1) {
            if (col === 0) {
                setRow(0)
                setCol(2)
                return
            }
        }
        if (row === 2) {
            if (col === 0) {
                setRow(1)
                setCol(0)
                return
            }
            if (col === 2) {
                setRow(2)
                setCol(0)
                return
            }
        }
        if (row === 4 && col === 4) {
            setRow(2)
            setCol(2)
            return
        }
    }

    return {currFormattedGuess, turn, isCorrect, handleKeyup, goToRowCol, usedKeys, row, col, history}
}

export default useMathGame
