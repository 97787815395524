import React from "react"

export default function Directions() {
    const letsGoClicked = () => {
        window.localStorage.setItem('showDirections', JSON.stringify(false))
        var directionsModal = document.getElementById("directions-modal");
        directionsModal.style.display = 'none';
    }
    return (
        <div id="directions-modal" className="modal">
                <div className="outer">
                    <h1>How to play?</h1>
                    <div style={{textAlign: 'left', fontSize: '12px'}}>
                    <p>The game consists of 9 squares with one answer in the center square.</p>
                    <ol type="1">
                        <li>The top row of numbers, the left column of numbers, the right column of numbers, and the bottom row of numbers should add up to equal the center number.</li>
                        <li>You will be given one corner number and two middle numbers to start.  The center number answer will also be given.</li>
                        <li>For many numbers, there will be many mathematical solutions but only one correct solution for each row or column.</li>
                        <li>Numbers can be used more than once, and you must fill in each square with a number before you hit enter to find out if you have the correct answer.</li>
                        <li>A correct number in the correct square will turn green; a correct number in the wrong square will turn yellow; an incorrect number will turn gray.</li>
                        <li>To navigate the board, you can use the Back and Next buttons or click on the square you want to enter a number for.</li>
                        <li>You have 3 chances to solve Square in One, and you can share your results with family and friends! A new game starts each day.</li>
                    </ol>
                    <p>Good luck and have fun!</p>
                    </div>
                    <div className='share-box'>
                        <div onClick={letsGoClicked}>Let's Go!</div>
                    </div>
                </div>
        </div>
    )
}
