import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from "react-ga4";
// import reportWebVitals from './reportWebVitals';

ReactGA.initialize("G-XK47Y9CP5V");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Square in One" });
